import { warnOldBrowser } from './lib/browser';
import setupLongArticle from './lib/long-article';
import setupShare from './lib/share';
import setupSideNote from './lib/side-note';
import setupFootNote from './lib/foot-note';
import setupMenu from './lib/menu';
import setupCookies from './lib/cookies';
import setupOped from './lib/oped';
import setupDatePicker from './lib/date-picker';
import setupCarousel from './lib/carousel';
import setupChoice from './lib/choice';
import setupProgressBar from './lib/progress-bar';
import setupDonation from './lib/gl-donations';
import setupTrayCarousel from './lib/carousel-tray'; // Added line
import Masonry from 'masonry-layout';
import setupSearchResponsive from './lib/searchresponsive';
import setupSeriesCarousel from './lib/series-carousel';

function startApp() {
  warnOldBrowser();
  setupMenu();
  setupCookies();
  setupChoice();
  setupDonation();
  setupSearchResponsive();
  setupSeriesCarousel();

  if (document.querySelector('.js-date-picker')) {
    setupDatePicker();
  }

  if (document.querySelector('.js-carousel')) {
    setupCarousel();
  }

  if (document.querySelector('.dev-story__extra-carousel')) {
    setupTrayCarousel(); // Added line
  }

  if (document.querySelector('.js-progress-bar')) {
    setupProgressBar();
  }

  if (document.querySelector('.js-share-btn')) {
    setupShare();
  }

  if (document.querySelector('#long-article')) {
    setupLongArticle();
  }

  if (document.querySelector('#oped')) {
    setupOped();
  }

  if (document.querySelector('.js-side-note')) {
    setupSideNote();
  }

  if (document.querySelector('.js-foot-note')) {
    setupFootNote();
  }

  if (window.location.hash === '#english') {
    document.querySelector('.js-lang-checkbox').checked = true;
  }

  for (let grid of document.querySelectorAll('.js-masonry-grid')) {
    new Masonry(grid, {
      columnWidth: '.js-masonry-width',
      itemSelector: '.js-masonry-item',
      percentPosition: true,
      gutter: 20,
      originLeft: false,
    });
  }
}

if (document.readyState != 'loading') {
  startApp();
} else {
  document.addEventListener('DOMContentLoaded', () => startApp());
}
document.addEventListener('DOMContentLoaded', () => {
  // Get all buttons that open a modal
  var btns = document.querySelectorAll('.myBtn');

  // Add click event to each button
  btns.forEach(function (btn) {
    btn.onclick = function () {
      var modalId = btn.getAttribute('data-modal-id');
      var modal = document.getElementById(modalId);
      if (modal) {
        modal.style.display = 'block';
        document.body.classList.add('no-scroll'); // Prevent scrolling on body
      }
    };
  });

  // Get all <span> elements that close modals
  var spans = document.querySelectorAll('.close');

  // Add click event to each <span>
  spans.forEach(function (span) {
    span.onclick = function () {
      var modal = span.closest('.modal');
      if (modal) {
        modal.style.display = 'none';
        document.body.classList.remove('no-scroll'); // Re-enable scrolling on body
      }
    };
  });

  // Close modals when clicking outside and re-enable scrolling
  window.onclick = function (event) {
    if (event.target.classList.contains('modal')) {
      event.target.style.display = 'none';
      document.body.classList.remove('no-scroll');
    }
  };
});
