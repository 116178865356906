let timeout;

export default function () {
  // const stickyBtn = document.querySelector('#long-article .donate-btn');
  // const fixedBtn = document.querySelector('#long-article .long-content__latest .donate-btn');
  // const initialFixedBtnPosition = fixedBtn.getBoundingClientRect().left;

  setupPullovers();
  document.addEventListener('scroll', handleScroll, { passive: true });

  function handleScroll() {
    easeOutTableOfContents();
    // handleDonateBtnVisibility();
  }

  //   function toggleButtonsVisibility(showStickyBtn) {
  //     stickyBtn.style.opacity = showStickyBtn ? 100 : 0;
  //     fixedBtn.style.opacity = showStickyBtn ? 0 : 100;
  //   }

  //   function adjustFixedButtonPosition(clientWidth) {
  //     if (clientWidth > 1546) {
  //       const fixedBtnLeft = initialFixedBtnPosition;
  //       const stickyBtnLeft = stickyBtn.getBoundingClientRect().left;
  //       const delta = Math.abs(fixedBtnLeft - stickyBtnLeft);
  //       const newPosition = fixedBtnLeft + delta - (clientWidth - 1546) / 2;
  //       fixedBtn.style.setProperty('left', `${newPosition}px`, 'important');
  //     } else if (clientWidth < 1546 && clientWidth > 1279) {
  //       fixedBtn.style.setProperty('left', '61.5%', 'important');
  //     } else if (clientWidth < 1279 && clientWidth > 768) {
  //       fixedBtn.style.setProperty('left', '40px', 'important');
  //     } else if (clientWidth < 768) {
  //       fixedBtn.style.setProperty('left', '30px', 'important');
  //     }
  //   }
  // }

  // function isLower(elem1, elem2) {
  //   const clientHeight =
  //     window.innerHeight || document.documentElement.clientHeight;
  //   const elem1DeltaTop = clientHeight - elem1.getBoundingClientRect().top;
  //   const elem2DeltaTop = clientHeight - elem2.getBoundingClientRect().top;
  //   return elem1DeltaTop < elem2DeltaTop;
  // }

  function easeOutTableOfContents() {
    restartTocTimer();

    const notes = document.querySelector('.js-toc-stop');
    const container = document.querySelector('.js-sticky-toc-container');
    const notesListTop =
      notes.getBoundingClientRect().top -
      150 -
      document.querySelector('.js-toc-content').offsetHeight;

    container.style.setProperty(
      '--toc-sticky-top',
      `${Math.min(92, notesListTop)}px`
    );
  }

  function setupPullovers() {
    calculatePullOverSideLength();
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    mediaQuery.addEventListener('change', calculatePullOverSideLength);
  }

  function calculatePullOverSideLength() {
    document
      .querySelectorAll('.long-content__pullover-shadow')
      .forEach((pullover) => {
        const parent = pullover.querySelector('.long-content__pullover');
        const child = pullover.querySelector('.js-text');
        let sideLength = 170;
        pullover.style.setProperty('--side-length', sideLength);

        while (child.offsetHeight > parent.offsetHeight && sideLength < 1000) {
          sideLength += 10;
          pullover.style.setProperty('--side-length', sideLength);
        }
      });
  }

  function hideToc() {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      document
        .querySelector('.js-toc-ease-out')
        .classList.add('long-content__table-of-contents_is-hidden');
      document
        .querySelectorAll('.js-share-checkbox')
        .forEach((share) => (share.checked = false));
    }, 4000);
  }

  function restartTocTimer() {
    document
      .querySelector('.js-toc-ease-out')
      .classList.remove('long-content__table-of-contents_is-hidden');
    hideToc();
  }
}

// function setupUnblur() {
//   document.querySelectorAll('.blur-this').forEach((element) => {
//     element.firstChild.addEventListener('click', function () {
//       element.classList.toggle('blur-this');
//     });
//   });
// }
