export default function setupSeriesCarousel() {
  let carousel = document.querySelector('.js-card-series');
  if (!carousel) return;

  let content = carousel.querySelector('.js-card-series-content');
  let items = carousel.querySelectorAll('.js-card-series-item');
  if (items.length <= 1) return;

  let dotsContainer = carousel.querySelector('.js-carousel-pagination');
  let page = 0;
  let touchStartX = 0;
  let touchEndX = 0;

  items.forEach(() => {
    let dot = document.createElement('div');
    dot.classList.add('carousel-pagination__dot');
    dotsContainer.appendChild(dot);
  });

  let dots = dotsContainer.querySelectorAll('.carousel-pagination__dot');

  function updateDots() {
    let previousControl = carousel.querySelector('.js-card-series-previous');
    let nextControl = carousel.querySelector('.js-card-series-next');
    previousControl.style.display = page === 0 ? 'none' : 'block';
    nextControl.style.display = page === items.length - 1 ? 'none' : 'block';
    dots.forEach((dot, index) => {
      dot.classList.toggle('carousel-pagination__dot--active', index === page);
    });
  }

  function handleSwipe() {
    if (touchEndX - touchStartX > 50) {
      if (page === 0) {
        content.classList.add('card-series__content--bounce');
        setTimeout(
          () => content.classList.remove('card-series__content--bounce'),
          300
        );
        return; // Prevent swipe left on the first page
      } else {
        page--;
      }
    } else if (touchStartX - touchEndX > 50) {
      if (page >= items.length - 1) {
        content.classList.add('card-series__content--bounce-back');
        setTimeout(
          () => content.classList.remove('card-series__content--bounce-back'),
          300
        );
        return;
      } else {
        page++;
      }
    }
    transition();
  }

  function transition() {
    content.style.setProperty(
      '--carousel-translate',
      `-${content.offsetWidth * page}px`
    );
    updateDots();
    stopInactiveVideos();
  }

  function stopInactiveVideos() {
    items.forEach((item, index) => {
      const video = item.querySelector('video');
      if (video) {
        if (index === page) {
          video.play();
        } else {
          video.pause();
          video.currentTime = 0;
        }
      }
    });
  }

  carousel
    .querySelector('.js-card-series-previous')
    .addEventListener('click', function () {
      if (page === 0) {
        content.classList.add('card-series__content--bounce');
        setTimeout(
          () => content.classList.remove('card-series__content--bounce'),
          300
        );
        return;
      } else {
        page--;
      }
      transition();
    });

  carousel
    .querySelector('.js-card-series-next')
    .addEventListener('click', function () {
      if (page >= items.length - 1) {
        content.classList.add('card-series__content--bounce-back');
        setTimeout(
          () => content.classList.remove('card-series__content--bounce-back'),
          300
        );
        return;
      } else {
        page++;
      }
      transition();
    });

  carousel.addEventListener('touchstart', function (event) {
    touchStartX = event.touches[0].clientX;
  });

  carousel.addEventListener('touchend', function (event) {
    touchEndX = event.changedTouches[0].clientX;
    handleSwipe();
  });

  updateDots();
  stopInactiveVideos();
}
